import { default as ai_45form_45builderxRPTIq8WJ9Meta } from "/home/smirnovmaks/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as edit3eR5GKaOsdMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexoWmLM1YkcsMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexaJfr3eMGprMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexMIAcKMT0s4Meta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareb1sHaG2E5WMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsmaM4jkHsyUMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsXi1CdE4L1uMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showVODJA5bglrMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestoIAFGjoUQZMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as index9eLK4L71ToMeta } from "/home/smirnovmaks/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeuStzHQTIQwMeta } from "/home/smirnovmaks/OpnForm/client/pages/home.vue?macro=true";
import { default as indexrGbQQ7KkuqMeta } from "/home/smirnovmaks/OpnForm/client/pages/index.vue?macro=true";
import { default as loginYzdgnYdVKaMeta } from "/home/smirnovmaks/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackxHIUl9ankEMeta } from "/home/smirnovmaks/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailCn3Qoh4VemMeta } from "/home/smirnovmaks/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_930u7CwyGiBDMeta } from "/home/smirnovmaks/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingRNPX2PHrCTMeta } from "/home/smirnovmaks/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policys9NIFGYGERMeta } from "/home/smirnovmaks/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerWGohuTN3WtMeta } from "/home/smirnovmaks/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokens2NfM3o6X7YMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountkf0r1FGWQcMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminSdDbCc7qXcMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingjRkSyo0CQEMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionszsYKGeEgMtMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexgWrDEsfMx3Meta } from "/home/smirnovmaks/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordDi6ewj1pi2Meta } from "/home/smirnovmaks/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileZgZc2qCfFWMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceUBxXTnmhPAMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settings6m8coqURIoMeta } from "/home/smirnovmaks/OpnForm/client/pages/settings.vue?macro=true";
import { default as errorX1hVeQ6nyVMeta } from "/home/smirnovmaks/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as success7erCu0azB2Meta } from "/home/smirnovmaks/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93QXFOqVQh84Meta } from "/home/smirnovmaks/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexNUHDP0pu0gMeta } from "/home/smirnovmaks/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93235NNOYUz8Meta } from "/home/smirnovmaks/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatespLSCldZi2mMeta } from "/home/smirnovmaks/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93iqS83TbgLMMeta } from "/home/smirnovmaks/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionseclRcaw7GYMeta } from "/home/smirnovmaks/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsfCjjayqWPjMeta } from "/home/smirnovmaks/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderxRPTIq8WJ9Meta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: edit3eR5GKaOsdMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showVODJA5bglrMeta?.name,
    path: "/forms/:slug()/show",
    meta: showVODJA5bglrMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexaJfr3eMGprMeta?.redirect,
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexMIAcKMT0s4Meta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareb1sHaG2E5WMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsmaM4jkHsyUMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsXi1CdE4L1uMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestoIAFGjoUQZMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: index9eLK4L71ToMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeuStzHQTIQwMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginYzdgnYdVKaMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailCn3Qoh4VemMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_930u7CwyGiBDMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingRNPX2PHrCTMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policys9NIFGYGERMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerWGohuTN3WtMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settings6m8coqURIoMeta?.name,
    path: "/settings",
    meta: settings6m8coqURIoMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountkf0r1FGWQcMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminSdDbCc7qXcMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingjRkSyo0CQEMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionszsYKGeEgMtMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexgWrDEsfMx3Meta?.redirect,
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordDi6ewj1pi2Meta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileZgZc2qCfFWMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceUBxXTnmhPAMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorX1hVeQ6nyVMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: success7erCu0azB2Meta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatespLSCldZi2mMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionseclRcaw7GYMeta || {},
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/home/smirnovmaks/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]